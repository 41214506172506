

.admin-panel-customer-details-back-button {
    background-color: var(--admin-primary-color);
    color: white;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    margin-top: 20px;
}

.admin-panel-customer-resend-invoice-container {

}

.admin-panel-customer-details-spinner-container {
    position: relative;
    left: 100%;
    transform: translateX(-100%);
    width: fit-content;
}

.admin-panel-customer-details-container {
    width: calc(100% - 60px);
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    column-gap: 20px;
    row-gap: 10px;
    position: relative;
}

.admin-panel-customer-details-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
}

@media(max-width: 650px) {
    .admin-panel-customer-details-section {
        grid-template-columns: 1fr;
    }
}

.admin-panel-customer-details-title {
    color: var(--admin-primary-color);
    margin-top: 30px;
    margin-bottom: 15px;
}

.admin-panel-customer-details-field-container {
    display: grid;
    grid-template-rows: auto 1fr;
}

.admin-panel-customer-details-switch-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media(max-width: 450px) {
    .admin-panel-customer-details-switch-container {
        grid-template-columns: 1fr;
        row-gap: 10px;
    }
}

.admin-panel-customer-details-switch-wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
}

.admin-panel-customer-details-field-title {
    
}

.admin-panel-customer-details-field-input {
    border: 1px solid var(--admin-primary-color);
    align-self: start;
    height: 25px;
    margin-top: 5px;
    cursor: initial;
    padding-left: 5px;
}

.admin-panel-customer-details-field-text-area {
    border: 1px solid var(--admin-primary-color);
    height: 100px;
    margin-top: 5px;
    padding-left: 5px;
    padding-top: 5px;
    cursor: initial;
    resize: none;
}

@media(max-width: 650px) {
    .admin-panel-customer-details-field-input {
        font-size: 16px;
    }

    .admin-panel-customer-details-field-text-area {
        font-size: 16px;
    }
}

.admin-panel-customer-details-save-container {
    margin-top: 20px;
}

.admin-panel-customer-details-save-button {
    background-color: var(--admin-primary-color);
    color: white;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    position: relative;
    left: 100%;
    transform: translateX(-100%);
}