
.authenticationErrorPageContainer {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10000;
}

.authenticationErrorBackdrop {
	position: absolute;
	left: 0;
	top: 0;
    min-height: 100vh;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.55);
	z-index: 1;
}

.authenticationErrorContainer {
    border: 7px solid #bbcad6;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    min-height: 200px;
    width: calc(100% - 25px);
    max-width: 750px;
    display: flex;
    flex-direction: column;
    background-color: #FCFCFC;
    overflow-y: auto;
    border-radius: 4px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.45);
    z-index: 3;
}

.unauthenticated-popup-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-weight: bold;
	margin: 0px;
	font-size: 16px;
	color: black;
	background-color: #bbcad6;
	padding-left: 1em;
	padding-top: 0.2em;
	padding-bottom: 0.4em;
	margin-bottom: 1em;
}

.unauthenticated-button {
	padding: 3px 25px;
	border-radius: 5px;
	font-size: 14px;
	color: black;
	border: none;
	background-color: #bbcad6;
    min-height: 24px;
    cursor: pointer;
}