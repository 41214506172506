

:root {
    --admin-primary-color: #1D4671
}

.admin-login-logo {
    display: flex;
	position: fixed;
	left: 0;
	top: 0;
    z-index: 3;
    width: 100%;
}

.admin-logo-text {
    margin-top: 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    font-size: 28px;
}

.admin-logo {
	height: 10em;
	z-index: 1;
}

.login-box-admin-header {
    text-align: center;
    margin: 0px;
    margin-bottom: 5px;
}

.admin-panel-wrapper {
    min-height: 100vh;
    width: 100%;
}

.admin-panel-sidebar-container {
    height: 100vh;
    width: 300px;
    background-color: var(--admin-primary-color);
    display: inline-grid;
    grid-template-rows: 80px auto 40px;
    vertical-align: top;
}

.admin-panel-sidebar-logo {
    width: 80%;
    justify-self: center;
    align-self: center;
}

.admin-panel-sidebar-links-wrapper {
    height: 100%;
}

.admin-panel-sidebar-link {
    cursor: pointer;
    padding: 10px 0px;
    background-color: #183b61;
}

.admin-panel-sidebar-link-selected {
    background-color: #0F2D4D;
    font-weight: bold;
}

.admin-panel-sidebar-link-text {
    color: white;
    margin: 0px;
    margin-left: 10px;
}

.admin-panel-sidebar-footer-container {
    height: 100%;
    width: 100%;
    background-color: #0F2D4D;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
}

.admin-panel-sidebar-logout-button {
    text-decoration: underline;
    background-color: rgba(0, 0, 0, 0);
    color: white;
    border: none;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

@media(max-width: 650px) {
    .admin-panel-page-title-text {
        margin-bottom: 10px;
    }
}

.Toastify__toast {
    color: black !important;
}

.Toastify__progress-bar {
    background: var(--admin-primary-color) !important;
}

.admin-panel-navbar-container {
    height: 60px;
    width: 100%;
    background-color: var(--admin-primary-color);
    display: grid;
    position: sticky;
    top: 0px;
    z-index: 100;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.admin-panel-navbar-logo {
    /* height: 100%; */
    width: 175px;
    margin-left: 10px;
}

.admin-panel-navbar-links-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    width: fit-content;
    justify-self: end;
}

.admin-panel-navbar-burger-container {
    display: none;
}

@media(max-width: 650px) {
    .admin-panel-navbar-links-wrapper {
        display: none;
    }

    .admin-panel-navbar-burger-container {
        display: grid;
        grid-template-rows: 2px 1fr 2px;
        align-items: center;
        cursor: pointer;
        height: 20px;
        justify-self: end;
        margin-right: 10px;
    }

    .admin-panel-navbar-burger-dash {
        height: 2px;
        width: 25px;
        background-color: #F79D23;
    }
}

.admin-panel-navbar-link {
    color: white;
    margin: 0px;
    cursor: pointer;
    margin: 0px 20px;
}

.admin-panel-navbar-link-selected {
    font-weight: bold;
}

.admin-panel-navbar-logout-button {
    background-color: #F79D23;
    color: white;
    border: 0px;
    height: 35px;
    width: 150px;
    margin: 0px 10px 0px 20px;
    cursor: pointer;
}

.admin-panel-mobile-nav-container {
    display: none;
    position: fixed;
    top: -200px;
}

@media(max-width: 650px) {
    .admin-panel-mobile-nav-container {
        display: grid;
        position: fixed;
        grid-template-rows: auto 1fr;
        top: -200px;
        height: 200px;
        background-color: var(--admin-primary-color);
        width: 100%;
        z-index: 101;
        transition-duration: 200ms;
    }

    .admin-panel-mobile-nav-container-open {
        top: 0px;
    }

    .admin-panel-mobile-nav-close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }

    .admin-panel-mobile-nav-close-button:before, .admin-panel-mobile-nav-close-button:after {
        content: "";
        background-color: #F79D23;
        height: 20px;
        width: 2px;
        border-radius: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
    }

    .admin-panel-mobile-nav-close-button:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    .admin-panel-mobile-nav-close-button:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .admin-panel-mobile-nav-logo {
        width: 200px;
        justify-self: center;
        margin-top: 20px;
        align-self: center;
    }

    .admin-panel-mobile-nav-links-wrapper {
        justify-self: center;
        /* padding-bottom: 20px; */
    }

    .admin-panel-mobile-nav-link {
        color: white;
        text-align: center;
    }

    .admin-panel-mobile-nav-logout-button {
        background-color: #F79D23;
        color: white;
        border: 0px;
        height: 35px;
        width: 150px;
        margin: 0px 10px 0px 20px;
        cursor: pointer;
    }
}