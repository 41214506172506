html {
    background-color: #F4F4F4;
}

.admin-panel-orders-container {
    /* height: calc(100vh - 20px); */
    min-height: calc(100vh - 83px);
    width: calc(100% - 20px);
    display: inline-block;
    padding-left: 20px;
    background-color: #F4F4F4;
    overflow-x: auto;
    padding-bottom: 20px;
}

.admin-panel-orders-header-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    width: calc(100% - 20px);
}

@media(max-width: 650px) {
    .admin-panel-orders-header-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        margin-bottom: 15px;
    }
}

.admin-panel-orders-button-wrapper {
    height: fit-content;
    align-self: center;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 10px;
}

@media(max-width: 650px) {
    .admin-panel-orders-button-wrapper {
        width: fit-content;
    }
}

.admin-panel-orders-add-customer-button {
    background-color: var(--admin-primary-color);
    color: white;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    height: fit-content;
    width: fit-content;
    align-self: center;
}

.admin-panel-orders-search-container {
    width: calc(100% - 60px);
    padding: 20px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 10px;
}

@media(max-width: 900px) {
    .admin-panel-orders-search-container {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 650px) {
    .admin-panel-orders-search-container {
        grid-template-rows: repeat(1fr, 6);
        grid-template-columns: 1fr;
    }
}

.admin-panel-orders-search-field-container {
    display: grid;
    grid-template-rows: auto auto;
}

.admin-panel-orders-search-field-title {
    font-size: 16px;
}

.admin-panel-orders-search-field-input {
    border: 1px solid var(--admin-primary-color);
    height: 25px;
    margin-top: 5px;
    cursor: initial;
    padding-left: 5px;
}

@media(max-width: 650px) {
    .admin-panel-orders-search-field-input {
        font-size: 16px;
    }
}

.admin-panel-orders-search-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    grid-template-rows: auto 30px;
}

.admin-panel-orders-clear-button {
    background-color: #3C3C3C;
    color: white;
    cursor: pointer;
    border: none;
    grid-row: 2;
}

.admin-panel-orders-search-button-wrapper {
    grid-row: 2;
    display: grid;
    justify-items: center;
    width: 100%;
}

.admin-panel-orders-search-button {
    background-color: var(--admin-primary-color);
    color: white;
    cursor: pointer;
    border: none;
    width: 100%;
}

.admin-panel-orders-info-text {
    color: rgb(100, 100, 100);
    margin-bottom: 10px;
    font-size: 14px;
}

.admin-panel-orders-history-container {
    background-color: white;
    width: calc(100% - 20px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    position: relative;
}

.admin-panel-orders-history-table-container {
    width: 100%;
    overflow-y: auto;
}

.admin-panel-orders-history-table-wrapper {
    min-width: 1000px;
}

.admin-panel-orders-history-titles-container {
    display: grid;
    width: 100%;
    grid-template-columns: 15% 15% 25% 10% 17.5% 17.5%;
    justify-items: center;
    border-bottom: 1px solid var(--admin-primary-color);
}

.admin-panel-orders-history-title-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
}

.admin-panel-orders-history-title {
    font-weight: bold;
    margin: 10px 0px;
    cursor: pointer;
    text-align: start;
    user-select: none;
}

.admin-panel-orders-history-arrow {
    position: relative;
    height: 10px;
    width: 15px;
    /* background-color: black; */
    align-self: center;
    justify-self: center;
    margin-left: 5px;
    cursor: pointer;
    transform: rotateZ(180deg);
}

.admin-panel-orders-history-arrow-rotated {
    transform: rotateZ(0deg);
}

.admin-panel-orders-history-arrow:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
    border-radius: 2px;
}

.admin-panel-orders-history-arrow-hidden {
    opacity: 0.2;
}

.admin-panel-orders-history-data-container {
    display: grid;
    width: 100%;
    grid-template-columns: 15% 15% 25% 10% 17.5% 17.5%;
    justify-items: center;
    cursor: pointer;
    transition-duration: 100ms;
}

.admin-panel-orders-history-data-container:nth-child(odd) {
    background-color: rgb(245, 245, 245);
}

.admin-panel-orders-history-data-container:hover {
    background-color: var(--admin-primary-color);
    color: white;
}

.admin-panel-orders-history-data {
    font-size: 12px;
    color: inherit;
    text-align: center;
}

.admin-panel-order-history-footer-container {
    height: 35px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background-color: rgb(250, 250, 250);
}

.admin-panel-order-history-footer-text {
    margin: 0px;
    font-size: 14px;
    color: rgb(100, 100, 100);
}

.admin-panel-order-history-spinner-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(175, 175, 175, 0.65);
    display: grid;
    align-items: center;
    justify-items: center;
    top: 0;
    left: 0;
}