

.bool-switch-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid var(--admin-primary-color);
    height: 32px;
    width: 90px;
    margin-top: 5px;
    cursor: pointer;
}

.bool-switch-box {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.bool-switch-box-filled {
    background-color: var(--admin-primary-color);
}

.bool-switch-box-filled:before, .bool-switch-box-filled:after {
    background-color: white !important;
}

.bool-switch-false {
    position: relative;
}

.bool-switch-false:before, .bool-switch-false:after {
    content: "";
    position: absolute;
    top: 50%;
    left: calc(50% - 1px);
    height: 20px;
    width: 3px;
    border-radius: 3px;
    background-color: rgb(180, 180, 180);
}

.bool-switch-false:before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.bool-switch-false:after {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.bool-switch-true {
    position: relative;
}

.bool-switch-true:before {
    content: "";
    position: absolute;
    top: 52%;
    left: calc(50% + 3px);
    height: 20px;
    width: 3px;
    border-radius: 10px;
    background-color: rgb(180, 180, 180);
    transform: translate(-50%, -50%) rotate(40deg);
}

.bool-switch-true:after {
    content: "";
    position: absolute;
    top: 63%;
    left: calc(50% - 6px);
    height: 10px;
    width: 3px;
    border-radius: 6px;
    background-color: rgb(180, 180, 180);
    transform: translate(-50%, -50%) rotate(-45deg);
}