

.admin-panel-overview-container {
    min-height: calc(100vh - 83px);
    width: calc(100% - 20px);
    display: inline-block;
    padding-bottom: 20px;
    padding-left: 20px;
    background-color: #F4F4F4;
    overflow-y: auto;
}

.admin-panel-overview-header-button-wrapper {
    display: grid;
    grid-template-columns: 1fr 110px;
    column-gap: 10px;
    width: fit-content;
    justify-items: center;
    align-items: center;
    position: relative;
}

.admin-panel-overview-with-change-container {
    background-color: white;
    display: inline-grid;
    margin-right: 20px;
    padding: 20px;
    margin-bottom: 10px;
    padding-right: 50px;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

@media(max-width: 540px) {
    .admin-panel-overview-with-change-container {
        width: calc(100% - 90px);
    }
}

.admin-panel-overview-with-change-title {
    margin-top: 0px;
    font-size: 16px;
    user-select: initial;
}

.admin-panel-overview-with-change-value {
    font-weight: bold;
    font-size: 26px;
    color: var(--admin-primary-color);
    margin: 0px;
    user-select: initial;
}

.admin-panel-overview-with-change-difference-container {
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column: 2;
    margin-left: 40px;
    width: fit-content;
    align-self: center;
    padding: 5px;
    border-radius: 3px;
    justify-self: end;
}

.overview-change-positive {
    background-color: #1EB93B;
}

.overview-change-negative {
    background-color: #B91E1E;
}

.admin-panel-overview-with-change-difference-text {
    margin: 0px;
    color: white;
    user-select: initial;
}

.admin-panel-overview-item-container {
    display: inline-grid;
    width: calc(100% - 60px);
    background-color: white;
    grid-template-columns: 1fr auto;
    padding: 10px 20px;
    margin-top: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.admin-panel-overview-item-container:nth-child(odd) {
    background-color: #F8F8F8;
}

.admin-panel-overview-item-container:nth-last-child(1) {
    margin-bottom: 20px;
}

.admin-panel-overview-item-title {
    margin: 0px;
}

.admin-panel-overview-item-value {
    margin: 0px;
    color: var(--admin-primary-color);
    font-weight: bold;
}